import React, {useEffect, useState} from "react";
import Navbar from '../Components/Navbar'
import Home1 from '../Img/home1.png'
import Home2 from '../Img/home2.png'
import Home3 from '../Img/home3.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../Components/Footer'
import Whatsapp from '../Components/Whatsapp'

<title>Box Shipping usa - Envios internacionales</title>

function App(){
    // DATA AOS
    useEffect(() => {
        AOS.init();
    }, []);

    // RESPONSIVE
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };
        
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
        }, []);
    return(
        <>
        <div>
            <Navbar/>
        </div>

        <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-item active">
            <img src={Home1} className="d-block w-100" alt="Compras en línea
            Compras en línea,
            Envíos internacionales,
            Envíos desde el extranjero,
            Compras seguras,
            Envíos rápidos,
            Compras de productos extranjeros,
            Servicios de reenvío de paquetes,
            Compras en línea confiables,
            Envíos internacionales a domicilio,
            Soluciones de envío confiables,
            Compra en el exterior,
            Compra en usa,
            Compra en china,
            Compra,
            Shipping,
            Envios,
            Tiendamia,
            Amazon,
            Ebay,
            Alibaba,
            Walmart,
            Bestbuy,
            Aerobox,
            Dropshipping,
            Courier,
            Tda,
            Traer cosas de estados unidos,
            Box shipping usa,
            box shipping,
            boxshipping,
            shipping box traductor,
            amazon shipping boxes for sellers,
            box estados unidos,
            how to get amazon shipping boxes, 
            amazon shipping box sizes,
            po box usa,
            po box miami,
            anytime box,
            compras en el exterior por internet,
            compras en el exterior por internet 2024,
            compras en el exterior puerta a puerta,
            compras en el exterior milei,
            compras en el exterior 2024,
            afip compras en el exterior,
            compras en el exterior con tarjeta,
            paginas para comprar en el exterior argentina,"/>
            </div>
            <div className="carousel-item">
            <img src={Home2} className="d-block w-100" alt="Compras en línea
           Compras en línea,
           Envíos internacionales,
           Envíos desde el extranjero,
           Compras seguras,
           Envíos rápidos,
           Compras de productos extranjeros,
           Servicios de reenvío de paquetes,
           Compras en línea confiables,
           Envíos internacionales a domicilio,
           Soluciones de envío confiables,
           Compra en el exterior,
           Compra en usa,
           Compra en china,
           Compra,
           Shipping,
           Envios,
           Tiendamia,
           Amazon,
           Ebay,
           Alibaba,
           Walmart,
           Bestbuy,
           Aerobox,
           Dropshipping,
           Courier,
           Tda,
           Traer cosas de estados unidos,
           Box shipping usa,
           box shipping,
           boxshipping,
           shipping box traductor,
           amazon shipping boxes for sellers,
           box estados unidos,
           how to get amazon shipping boxes, 
           amazon shipping box sizes,
           po box usa,
           po box miami,
           anytime box,
           compras en el exterior por internet,
           compras en el exterior por internet 2024,
           compras en el exterior puerta a puerta,
           compras en el exterior milei,
           compras en el exterior 2024,
           afip compras en el exterior,
           compras en el exterior con tarjeta,
           paginas para comprar en el exterior argentina,"/>
            </div>
            <div className="carousel-item">
            <img src={Home3} className="d-block w-100" alt="Compras en línea
           Compras en línea,
           Envíos internacionales,
           Envíos desde el extranjero,
           Compras seguras,
           Envíos rápidos,
           Compras de productos extranjeros,
           Servicios de reenvío de paquetes,
           Compras en línea confiables,
           Envíos internacionales a domicilio,
           Soluciones de envío confiables,
           Compra en el exterior,
           Compra en usa,
           Compra en china,
           Compra,
           Shipping,
           Envios,
           Tiendamia,
           Amazon,
           Ebay,
           Alibaba,
           Walmart,
           Bestbuy,
           Aerobox,
           Dropshipping,
           Courier,
           Tda,
           Traer cosas de estados unidos,
           Box shipping usa,
           box shipping,
           boxshipping,
           shipping box traductor,
           amazon shipping boxes for sellers,
           box estados unidos,
           how to get amazon shipping boxes, 
           amazon shipping box sizes,
           po box usa,
           po box miami,
           anytime box,
           compras en el exterior por internet,
           compras en el exterior por internet 2024,
           compras en el exterior puerta a puerta,
           compras en el exterior milei,
           compras en el exterior 2024,
           afip compras en el exterior,
           compras en el exterior con tarjeta,
           paginas para comprar en el exterior argentina,"/>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
        </div>

        <div style={{
            display:'flex',
            justifyContent:'center',
            alignContent:'center',
            alignItems:'center',
            flexDirection:'column',
            padding:'5%'
        }}>
            <div data-aos="fade-up" style={{width:'100%'}}><h2 style={{fontFamily:'titleBold', fontSize:windowWidth<1200?'2em':'4em',color:'#4480c2',padding:'3%'}}>¿Cómo funciona?</h2></div>
            <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',width:'80%'}} data-aos="fade-up">
                <h1 style={{fontFamily:'titleBold',fontSize:windowWidth<1200?'3em':'7em',color:'#4480c2',padding:'1%'}}>01</h1>
                <h3 style={{fontFamily:'title',fontSize:windowWidth<1200?'1em':'2em',color:'#4480c2'}}>Realizá la compra en el proveedor que quieras colocando la dirección de nuestro warehouse ubicado en Miami.</h3>                
            </div> 
            <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',width:'80%'}} data-aos="fade-up">
                <h1 style={{fontFamily:'titleBold',fontSize:windowWidth<1200?'3em':'7em',color:'#4480c2',padding:'1%'}}>02</h1>
                <h3 style={{fontFamily:'title',fontSize:windowWidth<1200?'1em':'2em',color:'#4480c2'}}>Cuando llega tu pedido lo embalamos para mayor seguridad.</h3>                
            </div> 
            <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',width:'80%'}} data-aos="fade-up">
                <h1 style={{fontFamily:'titleBold',fontSize:windowWidth<1200?'3em':'7em',color:'#4480c2',padding:'1%'}}>03</h1>
                <h3 style={{fontFamily:'title',fontSize:windowWidth<1200?'1em':'2em',color:'#4480c2'}}>Lo despachamos y en 4 días lo tenes Argentina.</h3>                
            </div>
        </div>

        <div style={{
            display:'flex',
            justifyContent:'center',
            alignContent:'center',
            alignItems:'center',
            flexDirection:'column',
            padding:'5%'
        }}>
            <div data-aos="fade-up" style={{width:'100%'}}><h2 style={{fontFamily:'titleBold', fontSize:windowWidth<1200?'2em':'4em',color:'#4480c2',padding:'3%'}}>Ventajas</h2></div>
            <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',width:'80%'}} data-aos="fade-up">
                <h3 style={{fontFamily:'title',fontSize:windowWidth<1200?'1em':'2em',color:'#4480c2'}}>Con Box Shipping USA, puedes enviar paquetes
                 de cualquier tamaño ya sea desde China a Estados Unidos o desde Estados Unidos hacia Argentina. 
                 Nos encargamos de que tus envíos lleguen de manera segura y puntual.</h3>                
            </div> 
        </div>

        <div style={{
            display:'flex',
            justifyContent:'center',
            alignContent:'center',
            alignItems:'center',
            flexDirection:'column',
            padding:'5%'
        }}>
            <div data-aos="fade-up" style={{width:'100%'}}><h2 style={{fontFamily:'titleBold', fontSize:windowWidth<1200?'2em':'4em',color:'#4480c2',padding:'3%'}}>Nuestra misión</h2></div>
            <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',width:'80%'}} data-aos="fade-up">
                <h3 style={{fontFamily:'title',fontSize:windowWidth<1200?'1em':'2em',color:'#4480c2'}}>Nuestra misión en Box Shipping USA, es proporcionar un servicio de envíos 
                de calidad excepcional tanto para empresas como para personas. Nos comprometemos a ofrecer soluciones de mensajería confiables,
                 seguras y eficientes que superen las expectativas de nuestros clientes en cada entrega. Con un enfoque centrado en la excelencia 
                 operativa y la atención al detalle, nos esforzamos por simplificar el proceso de envío y garantizar la satisfacción total de 
                 nuestros clientes en cada paso del camino. Estamos dedicados a facilitar conexiones significativas y proporcionar un servicio de 
                 mensajería que sea sinónimo de confianza, integridad y calidad.</h3>                
            </div> 
        </div>
        <Whatsapp/>
        <Footer/>
        </>
    )
}
export default App