import React, {useState} from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

function App(){
	const [formularioEnviado, cambiarFormularioEnviado] = useState(false);

	const handleSubmit = () => {
	  const token = window.grecaptcha.getResponse();
	  if (!token) {
		alert('Por favor, complete el reCAPTCHA.');
		return;
	  }
	  // Aquí puedes enviar el formulario con el token de reCAPTCHA
	  console.log('Formulario enviado');
	  cambiarFormularioEnviado(true);
	  setTimeout(() => cambiarFormularioEnviado(false), 3000); // Cambia el estado después de 3 segundos
	};
	return(
		<>

		<Formik
		initialValues={{
			nombre: '',
			correo: '',
			origen: '',
			destino: '',
			link:'',
			mensaje:''
		}}
		validate={(valores) => {
			let errores = {};

			// Validacion nombre
			if (!valores.nombre) {
			errores.nombre = 'Por favor ingresa un nombre';
			} else if (!/^[a-zA-ZÀ-ÿ\s]{1,48}$/.test(valores.nombre)) {
			errores.nombre = 'El nombre solo puede contener letras y espacios.';
			}

			// Validacion correo
			if (!valores.correo) {
			errores.correo = 'Por favor ingresa un correo';
			} else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(valores.correo)) {
			errores.correo = 'Por favor ingresa un correo válido';
			}

			// Validacion select origen
			if (!valores.origen) {
			errores.origen = 'Por favor selecciona un origen';
			}

			// Validacion select destino
			if (!valores.destino) {
			errores.destino = 'Por favor selecciona un destino';
			}

			// Validacion campo link
			if (!valores.link) {
				errores.link = 'Por favor ingresa un link';
				}
			
			// Validacion campo mensaje
			if (!valores.mensaje) {
			errores.mensaje = 'Por favor ingresa un mensaje';
			}
			return errores;
		}}
		onSubmit={(valores, { resetForm }) => {
			resetForm();
			console.log('Formulario enviado');
			cambiarFormularioEnviado(true);
			setTimeout(() => cambiarFormularioEnviado(false));
		}}
		>
				{( {errors} )=>(
					<Form className="formulario" >

						<div>
							<label htmlFor="nombre">Nombre</label>
							<Field 
							type="text"
							id="nombre" 
							name="nombre" 
							placeholder="John Doe" 
							/>
								<ErrorMessage name="nombre" component={() => (
								<div className="error">{errors.nombre}</div>
								)} />
						</div>

						<div>
							<label htmlFor="nombre">Correo</label>
							<Field type="email" 
							id="correo" 
							name="correo" 
							placeholder="name@correo.com"
							/>
								<ErrorMessage name="correo" component={() => (
								<div className="error">{errors.correo}</div>
								)} />
						</div>

						<div>
							<label htmlFor="origen">Origen</label>
							<Field name="origen" as="select" style={{
								fontFamily: 'Open Sans, sans-serif',
								width: '100%',
								borderRadius: '5px',
								border: '2px solid #e2e2e2',
								fontSize: '18px',
								padding: '10px',
								marginBottom: '5px',
								color: '#1f1f1f'}}>
								<option value="">Select</option>
								<option value="China">China</option>
								<option value="Estados Unidos">Estados Unidos</option>
								<option value="Argentina">Argentina</option>
							</Field>
								<ErrorMessage name="origen" component={() => (
								<div className="error">{errors.origen}</div>
								)} />
						</div>

						<div>
							<label htmlFor="destino">Destino</label>
							<Field name="destino" as="select" style={{
								fontFamily: 'Open Sans, sans-serif',
								width: '100%',
								borderRadius: '5px',
								border: '2px solid #e2e2e2',
								fontSize: '18px',
								padding: '10px',
								marginBottom: '5px',
								color: '#1f1f1f'}}>>
								<option value="">Select</option>
								<option value="China">China</option>
								<option value="Estados Unidos">Estados Unidos</option>
								<option value="Argentina">Argentina</option>
							</Field>
								<ErrorMessage name="destino" component={() => (
								<div className="error">{errors.destino}</div>
								)} />
						</div>

						<div>
							<label htmlFor="link">Link del artículo</label>
							<Field name="link" as="textarea"/>
								<ErrorMessage name="link" component={() => (
								<div className="error">{errors.link}</div>
								)} />
						</div>

						<div>
							<label htmlFor="mensaje">Mensaje</label>
							<Field name="mensaje" as="textarea"/>
								<ErrorMessage name="mensaje" component={() => (
								<div className="error">{errors.mensaje}</div>
								)} />
						</div>

						<div className="g-recaptcha" data-sitekey="6LcdfbgpAAAAAFCO18r3ockXxf2UmhmIKEffYeS4"></div>

						<button type="submit">Enviar</button>
						{formularioEnviado && <p className="exito">Formulario enviado con éxito</p>}
					</Form>						
				)}
			</Formik>


		</>
	)
}
export default App