import React, {useEffect, useState} from "react";

function App(){
const [windowWidth, setWindowWidth] = useState(window.innerWidth);

useEffect(() => {
const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
};
    
    window.addEventListener('resize', handleWindowResize);

    return () => {
        window.removeEventListener('resize', handleWindowResize);
    };
    }, []);

    return(
        <>
        <div style={{
            width:'100%',
            height:'80vh',
            display:'flex',
            justifyContent:'center',
            alignContent:'center',
            alignItems:'center',
            backgroundColor:'#4480c2'
        }}>
            <div style={{
                display: 'flex',
                placeContent:'flex-start',
                flexDirection:windowWidth < 1200? 'column':'row',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap:'2%',
                width:'90%'
            }}>

                
                <div style={{width:'100%'}}>
                    <h2 style={{ fontFamily:'titleBold', fontSize:'1.5em', color:'#fff' }}>Contacto</h2>
                    <h3 style={{ fontFamily:'title', fontSize:'1em', color:'#fff'}}>+1 (786) 804-3616</h3>
                
                    <div class="card" style={{display:'flex',flexDirection:'row'}}>
                        <a href="https://www.instagram.com/boxshipping.usa/" target="_blank" class="socialContainer containerOne">
                        <i class="bi bi-instagram" style={{color:'#fff',width:'17px'}}/></a>
                        
                        <a href="https://www.facebook.com/profile.php?id=61555223063925" class="socialContainer containerTwo">
                        <i class="bi bi-facebook" style={{color:'#fff',width:'17px'}}/></a>

                        <a href="https://api.whatsapp.com/send?phone=+17868043616&text=Hola!%20Quiero%20info%20de%20" target="_blank" class="socialContainer containerThree">
                        <i class="bi bi-whatsapp" style={{color:'#fff',width:'17px'}}/></a>
                        
                    </div>             
                </div>
            </div>
        </div>
        <div style={{
            width:'100%',
            height:'8vh',
            backgroundColor:'#4480c2',
            display:'flex',
            justifyContent:'center',
            alignContent:'center',
            alignItems:'center',            
            textAlign:'center',
            flexDirection:'column'
        }}>
            <h6 style={{
                color:'#fff',
                fontFamily:'title',
                fontSize:'1em'
            }}>Todos los derechos reservados Box Shipping USA®</h6>
            <h6 style={{
                color:'#fff',
                fontFamily:'title',
                fontSize:'1em'
            }}>Sitio desarrollado por <a href="https://www.yunus.com.ar" target="_blank" style={{color:'#bababa',textDecoration:'none'}}>Yunus Studio</a></h6>
        </div>
        </>
    )
}
export default App