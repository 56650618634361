import './App.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Home from './Pages/Home'
import Services from './Pages/Services'
import Store from './Pages/Store'
import Contact from './Pages/Contact'

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Services' element={<Services />} />
        <Route path='/Store' element={<Store />} />
        <Route path='/Contact' element={<Contact />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
