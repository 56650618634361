import React from "react"
import Whatsapp from '../Img/WhatsApp.png'

function App(){
    return(
        <>
        <div class="whatsapp-btn">
            <a href="https://api.whatsapp.com/send?phone=+17868043616&text=Hola!%20Quiero%20info%20de%20" target="_blank">
                <img src={Whatsapp} alt="WhatsApp"/>
            </a>
        </div>
        </>
    )
}
export default App