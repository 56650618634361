import React, {useState, useEffect} from "react";
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Whatsapp from '../Components/Whatsapp'
import st1 from '../Img/Tiendas/bestbuy.png'
import st2 from '../Img/Tiendas/amazon.png'
import st3 from '../Img/Tiendas/walmart.png'
import st4 from '../Img/Tiendas/ebay.png'
import st5 from '../Img/Tiendas/newegg.png'
import st6 from '../Img/Tiendas/apple.png'
import st7 from '../Img/Tiendas/dell.png'
import st8 from '../Img/Tiendas/lenovo.png'
import st9 from '../Img/Tiendas/hp.png'
import st10 from '../Img/Tiendas/sony.png'
import st11 from '../Img/Tiendas/nike.png'
import st12 from '../Img/Tiendas/adidas.png'
import st13 from '../Img/Tiendas/northface.png'
import st14 from '../Img/Tiendas/goat.png'
import st15 from '../Img/Tiendas/gucci.png'
import st16 from '../Img/Tiendas/prada.png'
import st17 from '../Img/Tiendas/luisvuitton.png'
import st18 from '../Img/Tiendas/stockx.png'
import st19 from '../Img/Tiendas/sephora.png'
import st20 from '../Img/Tiendas/carters.png'
import st21 from '../Img/Tiendas/target.png'
import st22 from '../Img/Tiendas/gamestop.png'
import st23 from '../Img/Tiendas/lego.png'
import st24 from '../Img/Tiendas/autozone.png'
import st25 from '../Img/Tiendas/ridersmiami.png'
import st26 from '../Img/Tiendas/thehomedepot.png'

<title>Box Shipping usa - Envios internacionales</title>

function App(){
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };
        
    window.addEventListener('resize', handleWindowResize);

    return () => {
        window.removeEventListener('resize', handleWindowResize);
    };
    }, []);
    return(
        <>
        <Navbar/>
        <div alt="Compras en línea
            Envíos internacionales
            Envíos desde el extranjero
            Compras seguras
            Envíos rápidos
            Compras de productos extranjeros
            Servicios de reenvío de paquetes
            Compras en línea confiables
            Envíos internacionales a domicilio
            Soluciones de envío confiables
            Compra en el exterior
            Compra en usa 
            Compra en china 
            Compra 
            Shipping
            Envios
            Tiendamia
            Amazon
            Ebay
            Alibaba
            Walmart
            Bestbuy
            Aerobox
            Dropshipping
            Courier
            Tda
            Traer cosas de estados unidos
            Box shipping usa
            box shipping
            boxshipping">
            <h2 style={{
                fontFamily:'subtitle',
                fontSize:windowWidth<1200?'2em':'5em',
                color:'#4480c2',
                textAlign:'center',
                padding:'5%'
            }}>
                Tiendas recomendadas
            </h2>
            <div class="container text-center" style={{padding:'5%'}}>
                <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4">
                    <a href="https://www.bestbuy.com/?intl=nosplash" target="_blank"><div class="col"><img src={st1} style={{width:'70%'}}/></div></a>
                    <a href="https://www.amazon.com/-/es/gp/browse.html?node=468642&ref_=nav_em__cvg_0_2_12_10" target="_blank"><div class="col"><img src={st2} style={{width:'70%'}}/></div></a>
                    <a href="https://www.walmart.com/cp/video-games/2636?clickid=3BCQz0RtuxyPRIrw9M3Qo3arUkFS4MS-dWGcS80&irgwc=1&sourceid=imp_3BCQz0RtuxyPRIrw9M3Qo3arUkFS4MS-dWGcS80&veh=aff&wmlspartner=imp_10078&affiliates_ad_id=612734&campaign_id=9383&sharedid=aerobox.com.ar" target="_blank"><div class="col"><img src={st3} style={{width:'70%'}}/></div></a>
                    <a href="https://www.ebay.com/" target="_blank"><div class="col"><img src={st4} style={{width:'70%'}}/></div></a>
                    <a href="https://www.newegg.com/Gaming-VR/Store/ID-8?nm_mc=AFC-RAN-COM&utm_medium=affiliate&utm_source=afc-Skimlinks.com&ranMID=44583&AFFID=2116208&ranSiteID=TnL5HPStwNw-yCNxorzvgfQEy73keoNinA&AFFNAME=Skimlinks.com&ACRID=1&ranEAID=2116208&cm_mmc=afc-ran-com-_-Skimlinks.com&utm_campaign=afc-ran-com-_-Skimlinks.com&ASUBID=194289X1671874Xc10313611d0dca0fa64c5d386e2598b3&ASID=https%3A%2F%2Faerobox.com.ar%2F" target="_blank"><div class="col"><img src={st5} style={{width:'70%'}}/></div></a>
                    <a href="https://www.apple.com/" target="_blank"><div class="col"><img src={st6} style={{width:'70%'}}/></div></a>
                    <a href="https://www.dell.com/en-us" target="_blank"><div class="col"><img src={st7} style={{width:'70%'}}/></div></a>
                    <a href="https://www.lenovo.com/us/en/" target="_blank"><div class="col"><img src={st8} style={{width:'70%'}}/></div></a>
                    <a href="https://www.hp.com/us-en/home.html" target="_blank"><div class="col"><img src={st9} style={{width:'70%'}}/></div></a>
                    <a href="https://www.sony.com/en/" target="_blank"><div class="col"><img src={st10} style={{width:'70%'}}/></div></a>
                    <a href="https://www.nike.com/us/es/" target="_blank"><div class="col"><img src={st11} style={{width:'70%'}}/></div></a>
                    <a href="https://www.adidas.com/us" target="_blank"><div class="col"><img src={st12} style={{width:'70%'}}/></div></a>
                    <a href="https://www.thenorthface.com/en-us?_sr=1" target="_blank"><div class="col"><img src={st13} style={{width:'70%'}}/></div></a>
                    <a href="https://www.goat.com/" target="_blank"><div class="col"><img src={st14} style={{width:'70%'}}/></div></a>
                    <a href="https://www.gucci.com/us/en/" target="_blank"><div class="col"><img src={st15} style={{width:'70%'}}/></div></a>
                    <a href="https://www.prada.com/us/en.html" target="_blank"><div class="col"><img src={st16} style={{width:'70%'}}/></div></a>
                    <a href="https://us.louisvuitton.com/eng-us/homepage" target="_blank"><div class="col"><img src={st17} style={{width:'70%'}}/></div></a>
                    <a href="https://stockx.com/" target="_blank"><div class="col"><img src={st18} style={{width:'70%'}}/></div></a>
                    <a href="https://www.sephora.com/" target="_blank"><div class="col"><img src={st19} style={{width:'70%'}}/></div></a>
                    <a href="https://www.carters.com/" target="_blank"><div class="col"><img src={st20} style={{width:'70%'}}/></div></a>
                    <a href="https://www.target.com/" target="_blank"><div class="col"><img src={st21} style={{width:'70%'}}/></div></a>
                    <a href="https://www.gamestop.com/" target="_blank"><div class="col"><img src={st22} style={{width:'70%'}}/></div></a>
                    <a href="https://www.lego.com/es-us" target="_blank"><div class="col"><img src={st23} style={{width:'70%'}}/></div></a>
                    <a href="https://www.autozone.com/" target="_blank"><div class="col"><img src={st24} style={{width:'70%'}}/></div></a>
                    <a href="https://www.ridersmiami.com/--inventory?condition=pre-owned" target="_blank"><div class="col"><img src={st25} style={{width:'70%'}}/></div></a>
                    <a href="https://corporate.homedepot.com/" target="_blank"><div class="col"><img src={st26} style={{width:'70%'}}/></div></a>
                </div>
            </div>
        </div>
        <Whatsapp/>
        <Footer/>
        </>
    )
}
export default App