import React, {useEffect, useState} from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Fly from '../Img/fly.jpg'
import Warehouse from '../Img/warehouse.jpg'
import Consolidado from '../Img/consolidado.png'
import Whatsapp from '../Components/Whatsapp'
<title>Box Shipping usa - Envios internacionales</title>
function App(){
const [windowWidth, setWindowWidth] = useState(window.innerWidth);

useEffect(() => {
const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
};
    
window.addEventListener('resize', handleWindowResize);

return () => {
    window.removeEventListener('resize', handleWindowResize);
};
}, []);

    return(
        <>
        <Navbar/>
        {/* Envios internacionales */}
        <div style={{
            width:'100%',
            height:'100vh',
            display:'flex',
            justifyContent:'space-between',
            alignContent:'center',
            alignItems:'center',
            flexDirection:windowWidth<=1000?'column':'row'
        }}>
            <div style={{
                display:'flex',
                justifyContent:'center',
                alignContent:'center',
                alignItems:'center',
                width:windowWidth<=1000?'100%':'50%',
                height:'100vh',
                textAlign:'center',
                flexDirection:'column'
            }}>
                <h1 style={{fontFamily:'title', fontSize:windowWidth<1200?'2em':'4em', color:'#4480c2',}}>Envíos directos</h1>
                <div style={{maxWidth:'70%',display:'flex',justifyContent:'flex-start'}}>
                    <h2 style={{fontFamily:'title', fontSize:'1em', color:'#4480c2'}}>
                    Nuestro objetivo es conectar los mayores proveedores de articulos para que vos consigas eso que tanto queres. <br/><br/>
                    Por eso hacemos envíos desde <b>Estados Unidos</b> hacia <b>Argentina</b> y<br/>
                    Envíos desde <b>China</b> hacia <b>Argentina</b> o <b>Estados Unidos</b>.<br/></h2>
                </div>
            </div>
            <div style={{
                backgroundImage: `url(${Fly})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                height:'100vh',
                width:windowWidth<=1000?'100%':'50%',
            }} alt="Compras en línea,
            Compras en línea,
            Envíos internacionales,
            Envíos desde el extranjero,
            Compras seguras,
            Envíos rápidos,
            Compras de productos extranjeros,
            Servicios de reenvío de paquetes,
            Compras en línea confiables,
            Envíos internacionales a domicilio,
            Soluciones de envío confiables,
            Compra en el exterior,
            Compra en usa,
            Compra en china,
            Compra,
            Shipping,
            Envios,
            Tiendamia,
            Amazon,
            Ebay,
            Alibaba,
            Walmart,
            Bestbuy,
            Aerobox,
            Dropshipping,
            Courier,
            Tda,
            Traer cosas de estados unidos,
            Box shipping usa,
            box shipping,
            boxshipping,
            shipping box traductor,
            amazon shipping boxes for sellers,
            box estados unidos,
            how to get amazon shipping boxes, 
            amazon shipping box sizes,
            po box usa,
            po box miami,
            anytime box,
            compras en el exterior por internet,
            compras en el exterior por internet 2024,
            compras en el exterior puerta a puerta,
            compras en el exterior milei,
            compras en el exterior 2024,
            afip compras en el exterior,
            compras en el exterior con tarjeta,
            paginas para comprar en el exterior argentina,"/>
        </div>
        {/* Almacenamiento */}
        <div style={{
            width:'100%',
            height:'100vh',
            backgroundColor:'#fff',
            display:'flex',
            justifyContent:'space-between',
            alignContent:'center',
            alignItems:'center',
            flexDirection:windowWidth<=1000?'column':'row-reverse'
        }}>
            <div style={{
                display:'flex',
                justifyContent:'center',
                alignContent:'center',
                alignItems:'center',
                width:windowWidth<=1000?'100%':'50%',
                height:'100vh',
                textAlign:'center',
                flexDirection:'column'
            }}>
                <h1 style={{fontFamily:'title', fontSize:windowWidth<1200?'2em':'4em', color:'#4480c2',}}>Servicio de Almacenamiento</h1>
                <div style={{maxWidth:'70%',display:'flex',justifyContent:'flex-start'}}>
                    <h2 style={{fontFamily:'title', fontSize:'1em', color:'#4480c2'}}>
                    ¿Queres comprar y no tenes dirección donde llegue el paquete? tranquilo, 
                    podes alojarlo en nuestro warehouse y cuando viajes a Miami lo retiras.</h2>
                </div>
            </div>
            <div style={{
                backgroundImage: `url(${Warehouse})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                height:'100vh',
                width:windowWidth<=1000?'100%':'50%',
            }} alt="Compras en línea
            Compras en línea,
            Envíos internacionales,
            Envíos desde el extranjero,
            Compras seguras,
            Envíos rápidos,
            Compras de productos extranjeros,
            Servicios de reenvío de paquetes,
            Compras en línea confiables,
            Envíos internacionales a domicilio,
            Soluciones de envío confiables,
            Compra en el exterior,
            Compra en usa,
            Compra en china,
            Compra,
            Shipping,
            Envios,
            Tiendamia,
            Amazon,
            Ebay,
            Alibaba,
            Walmart,
            Bestbuy,
            Aerobox,
            Dropshipping,
            Courier,
            Tda,
            Traer cosas de estados unidos,
            Box shipping usa,
            box shipping,
            boxshipping,
            shipping box traductor,
            amazon shipping boxes for sellers,
            box estados unidos,
            how to get amazon shipping boxes, 
            amazon shipping box sizes,
            po box usa,
            po box miami,
            anytime box,
            compras en el exterior por internet,
            compras en el exterior por internet 2024,
            compras en el exterior puerta a puerta,
            compras en el exterior milei,
            compras en el exterior 2024,
            afip compras en el exterior,
            compras en el exterior con tarjeta,
            paginas para comprar en el exterior argentina,"/>
        </div>
        {/* Grandes compras */}
        <div style={{
            width:'100%',
            height:'100vh',
            display:'flex',
            justifyContent:'space-between',
            alignContent:'center',
            alignItems:'center',
            flexDirection:windowWidth<=1000?'column':'row'
        }}>
            <div style={{
                display:'flex',
                justifyContent:'center',
                alignContent:'center',
                alignItems:'center',
                width:windowWidth<=1000?'100%':'50%',
                height:'100vh',
                textAlign:'center',
                flexDirection:'column'
            }}>
                <h1 style={{fontFamily:'title', fontSize:windowWidth<1200?'2em':'4em', color:'#4480c2',}}>¿Quieres comprar para tu comercio?</h1>
                <div style={{maxWidth:'70%',display:'flex',justifyContent:'flex-start'}}>
                    <h2 style={{fontFamily:'title', fontSize:'1em', color:'#4480c2'}}>
                    Con <b>Box Shipping USA</b> podrás realizar tus pedidos desde <b>China</b> o <b>Estados Unidos</b> y te lo enviamos
                    a <b>Argentina</b> en unos pocos días, podes armar paquetes consolidados y recibirlos cuando lo desees.</h2>
                </div>
            </div>
            <div style={{
                backgroundImage: `url(${Consolidado})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                height:'100vh',
                width:windowWidth<=1000?'100%':'50%',
            }} alt="Compras en línea,
            Compras en línea,
            Envíos internacionales,
            Envíos desde el extranjero,
            Compras seguras,
            Envíos rápidos,
            Compras de productos extranjeros,
            Servicios de reenvío de paquetes,
            Compras en línea confiables,
            Envíos internacionales a domicilio,
            Soluciones de envío confiables,
            Compra en el exterior,
            Compra en usa,
            Compra en china,
            Compra,
            Shipping,
            Envios,
            Tiendamia,
            Amazon,
            Ebay,
            Alibaba,
            Walmart,
            Bestbuy,
            Aerobox,
            Dropshipping,
            Courier,
            Tda,
            Traer cosas de estados unidos,
            Box shipping usa,
            box shipping,
            boxshipping,
            shipping box traductor,
            amazon shipping boxes for sellers,
            box estados unidos,
            how to get amazon shipping boxes, 
            amazon shipping box sizes,
            po box usa,
            po box miami,
            anytime box,
            compras en el exterior por internet,
            compras en el exterior por internet 2024,
            compras en el exterior puerta a puerta,
            compras en el exterior milei,
            compras en el exterior 2024,
            afip compras en el exterior,
            compras en el exterior con tarjeta,
            paginas para comprar en el exterior argentina,"/>
        </div>
        <Whatsapp/>
        <Footer/>
        </>
    )
}

export default App