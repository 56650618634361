import React, {useEffect, useState} from "react";
import Logo from '../Img/Logo.jpg'

function App(){
    // RESPONSIVE
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };
        
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
        }, []);
    return(
        <>
        <div style={{
            display:'flex',
            justifyContent:'flex-start',
            alignContent:'center',
            alignItems:'flex-start',
            flexDirection:windowWidth <1200 ? 'column-reverse':'row',
            width:'100%',
            height:windowWidth<1200?'7vh':'18.5vh',
            backgroundColor:'#4480C2',
            boxShadow: '0px 0px 15px 1px'
        }}>
            <div style={{width:windowWidth<1200?'0%':'15%', height:'100%', backgroundColor:'#fff',display:'flex',justifyContent:'center'}}><img src={Logo} style={{width:'60%',padding:'1%'}}/></div>
            <div style={{display:'flex',flexDirection:'column', justifyContent:'space-between', height:'16vh',width:windowWidth<1200?'0%':'100%',zIndex:'1000'}}>
                <h2 style={{fontFamily:'title',fontSize:windowWidth<1200?'0em':'2em',color:'#fff',padding:'1%',textAlign:'center'}}>Envíos de USA A ARG</h2>
                <div style={{width:'100%', display:'flex',justifyContent:'flex-start', alignItems:'flex-end',position:windowWidth<1200?'fixed':''}}>
                <nav className="navbar navbar-expand-lg" style={{width:'100%', backgroundColor:'#fff'}}>
                    <div className="container-fluid">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarText">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/">Home</a>
                                </li>
                                <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="Services">Services</a>
                                </li>
                                <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="Store">Stores</a>
                                </li>
                                <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="Contact">Contact</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </nav>
                </div>
            </div>
        </div>
        </>
    )
}
export default App